import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/Layout';
import PlatformRoll from '../../components/PlatformRoll';
import ContentSection from '../../components/ContentSection';

import PageHero from '../../components/PageHero';
import CTA from '../../components/CTA';

import { Section } from '../../components/bulma';

import SEO from '../../components/SEO/SEO';

export default function PlatformIndexPage() {
	const data = useStaticQuery(PALTFORM);
	const { edges } = data.allMarkdownRemark;

	const items = edges.map(({ node }) => ({
		title: node.frontmatter.heading,
		text: node.frontmatter.subheading,
		image: node.frontmatter.image,
		slug: node.fields.slug
	}));

	return (
		<Layout>
			<SEO
				title="Solutions from each business type and size"
				description="From independent sales agents, to banks, to software developers, we've got you covered."
				// image={image.childImageSharp.sizes.src}
				pathname="/news"
				article
			/>

			<PageHero
				signup
				title="Solutions from each business type and size"
				subtitle="From independent sales agents, to banks, to software developers, we've got you covered"
				// image={featuredImage}
			/>

			<ContentSection items={items} />

			{/* <PlatformRoll /> */}
			<Section>
				<CTA />
			</Section>
		</Layout>
	);
}

const PALTFORM = graphql`
	query Solutions {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "solutions-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						heading
						subheading
						templateKey
						date(formatString: "MMMM DD, YYYY")
						image {
							childImageSharp {
								fluid(maxWidth: 500, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
